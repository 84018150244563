<template>
    <div id="AccountingClosingReport">
        <v-container fluid app>
            <ZnapHotGrid
                :options="options"
            >
            </ZnapHotGrid>
        </v-container>
    </div>
</template>

<script>
import Vue from 'vue'
import ZnapHotGrid from '@/components/znap/HotGridAccounting.vue'

export default {
    name:'AccountingClosingReport',

    components:{
        ZnapHotGrid
    },

    data() {
        return {
            options: [
                {
                    column: 'id_event',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Evento',
                    required: true,
                    filterable: false,
                    endpoint: [ Vue.prototype.$ipEvent, 'event' ],
                },
                {
                    column: 'year_month_date',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Mês/ Ano',
                    required: true,
                    filterable: false,
                    endpoint: [ Vue.prototype.$ipUnit, 'calendar' ],
                },
                {
                    column: 'id_cost_center',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Centro de custo',
                    required: false,
                    filterable: false,
                    endpoint: [ Vue.prototype.$ipOrganization, 'cost-center' ]
                },
                {
                    column: 'id_sales_channel',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Tipo de contrato',
                    required: false,
                    filterable: false,
                    endpoint: [ Vue.prototype.$ipSales, 'sales-channel' ]
                },
                {
                    column: 'id_sales_force',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Gerência executiva',
                    required: true,
                    filterable: false,
                    endpoint: [ Vue.prototype.$ipSales, 'sales-force' ],
                    conditions: [
                        {
                            AndOr: 'AND',
                            column: 'id_sales_force_type',
                            operator: '=',
                            value: 6
                        }
                    ]
                },
                {
                    column: 'id_sales_force_bp',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'BP',
                    required: true,
                    filterable: false,
                    endpoint: [ Vue.prototype.$ipSales, 'sales-force' ],
                    conditions: [
                        {
                            AndOr: 'AND',
                            column: 'id_sales_force_type',
                            operator: '=',
                            value: 7,
                        }
                    ]
                },
                {
                    column: 'id_accounting_closing_type',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Tipo do fechamento contábil',
                    required: true,
                    filterable: false,
                    endpoint: [ Vue.prototype.$ipAccount, 'accounting-closing-type' ]
                }
            ]

        }
    }
}
</script>

<style scoped>
</style>